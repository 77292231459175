<template>
	<div :style="bg" >
		<div style="margin-top: 25%; display: flex; flex-direction: column; align-items: center;">
			<img src="../../assets/success.png" />
			<p style="margin-top: 10px;">购买成功</p>
			<div style="display: flex; flex-direction: column; align-items: flex-start; padding: 10px 20px;">
				<span style="margin-top: 20px; font-weight: bold;">产品名称：
					<span style="color: #606266; font-weight: normal;">{{info.prodName}}</span>
				</span>
				<span style="margin-top: 5px; font-weight: bold;">收件人：
					<span style="color: #606266; font-weight: normal;">{{info.name}}</span>
				</span>
				<span style="margin-top: 5px; font-weight: bold;">手机号码：
					<span style="color: #606266; font-weight: normal;">{{info.phone}}</span>
				</span>
				<span style="margin-top: 5px; font-weight: bold;">自提位置：
					<span style="color: #606266; font-weight: normal;">{{info.address}}</span>
				</span>
			</div>
		</div>
		<div class="btn-content" style="margin-bottom: 0px; margin-top: 60px;">
			<v-btn rounded block @click="goMain" style="background-color: #009245; color: white; height: 40px;">
				返回首页
			</v-btn>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	
	export default {
		name: 'BuySuccess',
		created() {
			this.info = this.$route.params;
		},
		data:() => ({
			bg : {
				height: "100vh",
				width: "100%",
				backgroundImage: "url(" + require("../../assets/login/new-bg.png") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100% 100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "auto"
			},
			info: {}
		}),
		methods: {
			goMain() {
				this.$router.push({
					"name": "Main"
				});
			}
		}
	}
</script>

<style>
</style>
